function detectBrowserLanguage(): string {
  let userLang: string =
    navigator.language && navigator.language.length > 1
      ? navigator.language.substring(0, 2)
      : "en";

  if (!["es", "en"].includes(userLang)) {
    userLang = "en";
  }

  return userLang;
}

export { detectBrowserLanguage };
