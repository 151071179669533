import {
  IMenu,
  IItem,
  ICategory,
  IMenuStyles,
  TCategoryContent,
  IBusinessInfo,
  ICategoryContentTitle,
} from "@/types/menu";
import { IUser, IUserStore } from "@/types/user";
import {
  getFirestore,
  connectFirestoreEmulator,
  Firestore,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import {
  doc,
  onSnapshot,
  updateDoc,
  arrayUnion,
  FirestoreError,
  getDoc,
  setDoc,
  initializeFirestore,
  addDoc,
} from "firebase/firestore";
import { app, getMenuStore, getUserStore } from "./index";
import { deleteImages } from "@/firebase/storage";
import {
  isLocalDevEnabled,
  getFirestoreUrl_local,
  getFirestorePort_local,
} from "@/configuration/app-config";
import { IProduct, IPrice } from "@/types/stripe";
import {
  STRIPE_SUCCESS_URL,
  STRIPE_CANCEL_URL,
} from "@/configuration/app-config";
import { storeError } from "@/common/globalError";

// Always add a letter in front of the UUID. It could cause problems with query selectors if not.
import short from "short-uuid";
import { UndefinableType } from "@/types/general";

let db: Firestore;
if (isLocalDevEnabled()) {
  db = initializeFirestore(app, {
    experimentalAutoDetectLongPolling: true,
  });
  connectFirestoreEmulator(
    db,
    getFirestoreUrl_local(),
    getFirestorePort_local()
  );
} else {
  db = getFirestore(app);
}

const COLLECTIONS = {
  MENUS: "menus",
  USERS: "users",
  STRIPE_PRODUCTS: "stripe-products",
  STRIPE_PRICES: "prices",
  STRIPE_CUSTOMERS: "stripe-customers",
  STRIPE_CHECKOUT_SESSIONS: "checkout_sessions",
};

let unsubscribeMenu: any;
let unsubscribeUser: any;

async function initializeSnapshot() {
  const menuId: string = await _getUserMenuId();
  getMenuStore().setMenuId(menuId);
  getMenuStore().setDataUpdateOn();
  unsubscribeMenu = onSnapshot(
    doc(db, COLLECTIONS.MENUS, menuId),
    { includeMetadataChanges: true },
    (doc) => {
      if (doc.metadata.hasPendingWrites) {
        getMenuStore().setDataUpdateOn();
      } else {
        getMenuStore().setDataUpdateOff();
      }
      getMenuStore().setMenu(doc.data() as IMenu);
    },
    (error: FirestoreError) => {
      storeError(error);
      getMenuStore().setDataUpdateOff();
    }
  );
}

function detachFirestore() {
  unsubscribeMenu && unsubscribeMenu();
  unsubscribeUser && unsubscribeUser();
}

async function getUserById(uid: string): Promise<IUser> {
  if (!uid) {
    throw new Error("uid cannot be empty");
  }
  getMenuStore().setDataUpdateOn();
  let docRef: any;
  let docSnap: any;
  let count: number = 0;
  const MAX_RETRIES: number = 30;
  // eslint-disable-next-line no-constant-condition
  while (true) {
    try {
      count++;
      if (count >= MAX_RETRIES) {
        // More than MAX_RETRIES seconds
        throw new Error("get user: Timeout");
      }
      docRef = doc(db, COLLECTIONS.USERS, uid);
      docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        break;
      }
      // Wait for 1 second before retrying
      await new Promise((resolve) => setTimeout(resolve, 1000));
    } catch (error: any) {
      getMenuStore().setDataUpdateOff();
      storeError(error);
      throw error;
    }
  }
  getMenuStore().setDataUpdateOff();
  const userData = docSnap.data();

  const user: IUser = {
    uid: userData.uid,
    email: userData.email,
    createdOn: userData.createdOn ? userData.createdOn.toDate() : undefined,
    menuId: userData.menuId,
    role: userData.role,
    status: userData.status,
  };

  return user;
}

function initializeUserSnapshot(user: IUserStore) {
  if (!user) {
    throw new Error("uid cannot be empty");
  }

  unsubscribeUser = onSnapshot(
    doc(db, COLLECTIONS.USERS, user.uid),
    { includeMetadataChanges: true },
    (doc) => {
      if (doc.metadata.hasPendingWrites) {
        getMenuStore().setDataUpdateOn();
      } else {
        getMenuStore().setDataUpdateOff();
      }

      const dbUser = doc.data() as any;
      const newUser: IUserStore = {
        uid: dbUser.uid,
        email: dbUser.email,
        createdOn: dbUser.createdOn ? dbUser.createdOn.toDate() : undefined,
        menuId: dbUser.menuId,
        role: dbUser.role,
        status: dbUser.status,
        stripeRole: dbUser.stripeRole,
        emailVerified: user.emailVerified,
        displayName: user.displayName,
      };

      getUserStore().setUser(newUser);
    },
    (error: FirestoreError) => {
      storeError(error);
      getMenuStore().setDataUpdateOff();
    }
  );
}

async function createMenu(menu: IMenu) {
  try {
    await setDoc(doc(db, "collectionNames.menus", menu.menuId), menu);
  } catch (error: any) {
    storeError(error);
    throw error;
  }
}

async function _getUserMenuId(): Promise<string> {
  const uid = getUserStore().getUid;
  const user: IUser = await getUserById(uid);
  const menuId = user.menuId;
  if (!menuId) {
    throw new Error("User has no menus");
  }
  return menuId;
}

async function addItem(item: IItem) {
  try {
    item.id = "I" + short.generate();
    await updateDoc(doc(db, COLLECTIONS.MENUS, getMenuStore().getMenuId), {
      items: arrayUnion(item),
    });
  } catch (error: any) {
    storeError(error);
  }
}

async function updateItems(items: IItem[]) {
  try {
    await updateDoc(doc(db, COLLECTIONS.MENUS, getMenuStore().getMenuId), {
      items,
    });
  } catch (error: any) {
    storeError(error);
  }
}

async function updateItem(item: IItem) {
  const newItems: IItem[] = [...getMenuStore().allItems];
  const itemIndex = newItems.findIndex((i) => i.id === item.id);
  newItems[itemIndex] = item;
  await updateItems(newItems);
}

async function deteleItem(itemToDelete: IItem) {
  // Remove item ids from sections
  const newItems: IItem[] = [...getMenuStore().allItems];
  const itemIndex = newItems.findIndex((i) => i.id === itemToDelete.id);
  if (itemIndex < 0) {
    storeError(new Error("Item Not Found"));
    return;
  }
  newItems.splice(itemIndex, 1);

  const promises: any[] = [];
  promises.push(updateItems(newItems));
  if (itemToDelete.images) promises.push(deleteImages(itemToDelete.images));
  await Promise.all(promises);

  // Removing from Categories
  const categories = getMenuStore().allCategories;
  if (!categories) return;

  for (let i = 0; i < categories.length; i++) {
    const cat: ICategory = categories[i];
    if (cat.content && cat.content.length > 0) {
      const index = cat.content.findIndex(
        (content) => content.type === "ITEM" && content.id === itemToDelete.id
      );
      if (index >= 0) cat.content.splice(index, 1);
    }
  }

  await updateCategories(categories);
}

async function addCategory(category: ICategory) {
  category.id = "C" + short.generate();
  await updateDoc(doc(db, COLLECTIONS.MENUS, getMenuStore().getMenuId), {
    categories: arrayUnion(category),
  });
  return category.id;
}

async function deleteCategory(category: ICategory, deleteItems: boolean) {
  const newCategories: ICategory[] = [...getMenuStore().allCategories];
  const categoryIndex = newCategories.findIndex((i) => i.id === category.id);
  if (categoryIndex < 0) {
    storeError(new Error("Category Not Found"));
    return;
  }
  newCategories.splice(categoryIndex, 1);

  const promises: any[] = [];
  promises.push(updateCategories(newCategories));

  // Delete Items
  let itemsToDelete: UndefinableType<(IItem | ICategoryContentTitle)[]> =
    undefined;
  if (deleteItems) {
    itemsToDelete = getMenuStore().getCategoryContentItems(category.id);
  }

  if (itemsToDelete && itemsToDelete.length > 0) {
    itemsToDelete.forEach((item) => {
      // eslint-disable-next-line no-prototype-builtins
      if (!item.hasOwnProperty("type")) {
        promises.push(deteleItem(item as IItem));
      }
    });
  }

  await Promise.all(promises);
}

async function addItemToCategory(
  categoryId: string,
  item: TCategoryContent,
  position: UndefinableType<number>
) {
  const newCategories: ICategory[] = [...getMenuStore().allCategories];
  const categoryIndex = newCategories.findIndex((c) => c.id === categoryId);
  if (categoryIndex < 0) {
    storeError(new Error("Category Not Found"));
    return;
  }
  const category = newCategories[categoryIndex];
  const items: TCategoryContent[] = category.content || [];
  category.content = items;
  if (!position) {
    items.push(item);
  } else {
    items.splice(position, 0, item);
  }
  await updateCategories(newCategories);
}

async function updateCategoryTitle(categoryId: string, categoryTitle: string) {
  const updatedCategories: ICategory[] = [...getMenuStore().allCategories];
  const categoryIndex: number = updatedCategories.findIndex(
    (c) => c.id === categoryId
  );
  if (categoryIndex < 0) {
    storeError(new Error("Category Not Found"));
    return;
  }
  updatedCategories[categoryIndex].title[0].text = categoryTitle;
  await updateCategories(updatedCategories);
}

async function updateCategory(category: ICategory) {
  if (!category) {
    storeError(new Error("Category is null"));
    return;
  }
  const updatedCategories: ICategory[] = [...getMenuStore().allCategories];
  const categoryIndex: number = updatedCategories.findIndex(
    (c) => c.id === category.id
  );
  if (categoryIndex < 0) {
    storeError(new Error("Category Not Found"));
    return;
  }
  updatedCategories[categoryIndex] = category;
  await updateCategories(updatedCategories);
}

async function updateCategories(categories: ICategory[]) {
  try {
    await updateDoc(doc(db, COLLECTIONS.MENUS, getMenuStore().getMenuId), {
      categories,
    });
  } catch (error: any) {
    storeError(error);
  }
}

async function deleteItemFromCategory(category: ICategory, index: number) {
  if (!category.content || category.content.length < index + 1) {
    storeError(new Error("Item Not Found"));
    return;
  }
  category.content.splice(index, 1);
  const updatedCategories: ICategory[] = [...getMenuStore().allCategories];
  const categoryIndex: number = updatedCategories.findIndex(
    (c) => c.id === category.id
  );
  updatedCategories[categoryIndex] = category;
  await updateCategories(updatedCategories);
}

async function updateStyles(styles: IMenuStyles) {
  try {
    await updateDoc(doc(db, COLLECTIONS.MENUS, getMenuStore().getMenuId), {
      styles,
    });
  } catch (error: any) {
    storeError(error);
  }
}

async function updateStyle(
  value: string,
  styleName: string,
  propertyName?: string
) {
  const menuStyles: IMenuStyles | undefined = getMenuStore().getMenuStyles;
  if (menuStyles) {
    if (propertyName) menuStyles[styleName][propertyName] = value;
    else menuStyles[styleName] = value;
    await _updateFonts(menuStyles);
  } else {
    storeError(new Error("MenuStyles not found"));
  }
}

async function _updateFonts(styles: IMenuStyles) {
  try {
    await updateDoc(doc(db, COLLECTIONS.MENUS, getMenuStore().getMenuId), {
      styles,
    });
  } catch (error: any) {
    storeError(error);
  }
}

async function updateBusinessInformation(businessInfo: IBusinessInfo) {
  try {
    await updateDoc(doc(db, COLLECTIONS.MENUS, getMenuStore().getMenuId), {
      businessInfo,
    });
  } catch (error: any) {
    storeError(error);
  }
}

async function getStripeProducts(): Promise<IProduct[]> {
  getMenuStore().setDataUpdateOn();

  const productsRef = collection(db, COLLECTIONS.STRIPE_PRODUCTS);
  const q = query(productsRef, where("active", "==", true));
  const productSnap = await getDocs(q);

  const products: IProduct[] = [];

  for (const productDoc of productSnap.docs) {
    const productData = productDoc.data();

    const product: IProduct = {
      id: productDoc.id,
      active: productData.active,
      description: productData.description,
      name: productData.name,
      role: productData.role,
      taxCode: productData.tax_code,
      prices: [],
    };

    const pricesRef = collection(productDoc.ref, COLLECTIONS.STRIPE_PRICES);
    const qPrice = query(pricesRef, where("active", "==", true));
    const priceSnap = await getDocs(qPrice);

    for (const priceDoc of priceSnap.docs) {
      const priceData = priceDoc.data();
      const price: IPrice = {
        id: priceDoc.id,
        productId: priceData.product,
        active: priceData.active,
        interval: priceData.interval,
        taxBehavior: priceData.tax_behavior,
        currency: priceData.currency,
        unitAmount: priceData.unit_amount,
      };
      product.prices.push(price);
    }

    products.push(product);
  }
  getMenuStore().setDataUpdateOff();
  return products;
}

async function stripeStartPayment(price: IPrice) {
  getMenuStore().setDataUpdateOn();
  const selectedPrice = {
    price: price.id,
    quantity: 1,
  };

  const checkoutSession = {
    automatic_tax: true,
    tax_id_collection: true,
    collect_shipping_address: false,
    allow_promotion_codes: false,
    line_items: [selectedPrice],
    success_url: STRIPE_SUCCESS_URL,
    cancel_url: STRIPE_CANCEL_URL,
  };

  const customersRef = collection(db, COLLECTIONS.STRIPE_CUSTOMERS);
  const customerIdRef = doc(customersRef, getUserStore().getUid);
  const checkoutRef = collection(
    customerIdRef,
    COLLECTIONS.STRIPE_CHECKOUT_SESSIONS
  );
  const checkoutDoc = await addDoc(checkoutRef, checkoutSession);

  getMenuStore().setDataUpdateOff();

  onSnapshot(checkoutDoc, (doc: any) => {
    const data = doc.data();
    const { error, url } = data;
    if (error) {
      // Show an error to your customer and
      // inspect your Cloud Function logs in the Firebase console.
      storeError(error);
    }
    if (url) {
      // We have a Stripe Checkout URL, let's redirect.
      window.location.assign(url);
    }
  });
}

export {
  initializeSnapshot,
  addItem,
  updateItems,
  updateItem,
  deteleItem,
  addCategory,
  deleteCategory,
  updateCategories,
  addItemToCategory,
  updateCategory,
  deleteItemFromCategory,
  detachFirestore,
  getUserById,
  createMenu,
  updateStyle,
  updateStyles,
  updateCategoryTitle,
  updateBusinessInformation,
  getStripeProducts,
  stripeStartPayment,
  initializeUserSnapshot,
};
