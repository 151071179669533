import { computed, ref } from "vue";
import { defineStore } from "pinia";
import {
  IMenu,
  IItem,
  ICategory,
  IMenuStyles,
  TCategoryContent,
  IBusinessInfo,
  ICategoryContentTitle,
} from "@/types/menu";
import { MENU_STATUS, UndefinableType } from "@/types/general";
import { IHelpers, ItemCategoryAssociationSummary } from "@/types/menu-store";

export const useMenuStore = defineStore("MenuStore", () => {
  // State
  const menuId = ref<UndefinableType<string>>(undefined);
  const menu = ref<UndefinableType<IMenu>>(undefined);
  const helpers = ref<UndefinableType<IHelpers>>(undefined);

  // Internal Functions
  function _getStyleFor(
    styleGroup: string,
    styleName: string
  ): UndefinableType<string> {
    if (!menu.value || !menu.value.styles || !menu.value.styles[styleGroup]) {
      return undefined;
    }
    return menu.value.styles[styleGroup][styleName];
  }

  function _reset() {
    menuId.value = undefined;
    menu.value = undefined;
    helpers.value = undefined;
  }

  function _blankHelpers(): IHelpers {
    return {
      editIds: {
        editItemId: undefined,
        editCategoryId: undefined,
        selectedCategoryId: undefined,
        selectedSectionId: undefined,
      },
      storageStatus: {
        isStorageUpdating: undefined,
        error: undefined,
      },
    };
  }

  // Getters
  const getMenuId = computed<UndefinableType<string>>(() => menuId.value);
  const getMenu = computed<UndefinableType<IMenu>>(() => menu.value);
  const getStatus = computed<UndefinableType<MENU_STATUS>>(() =>
    menu.value ? menu.value.status : undefined
  );
  const getBusinessInfo = computed<UndefinableType<IBusinessInfo>>(() =>
    menu.value ? menu.value.businessInfo : undefined
  );
  const getBusinessName = computed<UndefinableType<string>>(() =>
    menu.value && menu.value.businessInfo
      ? menu.value.businessInfo.businessName
      : undefined
  );
  const allItems = computed<UndefinableType<IItem[]>>(() =>
    menu.value ? menu.value.items : undefined
  );
  const allCategories = computed<UndefinableType<ICategory[]>>(() =>
    menu.value ? menu.value.categories : undefined
  );
  const getEditItemId = computed<UndefinableType<string>>(() =>
    helpers.value && helpers.value.editIds
      ? helpers.value.editIds.editItemId
      : undefined
  );
  const getEditCategoryId = computed<UndefinableType<string>>(() =>
    helpers.value && helpers.value.editIds
      ? helpers.value.editIds.editCategoryId
      : undefined
  );
  const getSelectedCategoryId = computed<UndefinableType<string>>(() =>
    helpers.value && helpers.value?.editIds
      ? helpers.value.editIds.selectedCategoryId
      : undefined
  );
  const getSelectedSectionId = computed<UndefinableType<string>>(() =>
    helpers.value && helpers.value.editIds
      ? helpers.value.editIds.selectedSectionId
      : undefined
  );
  const getItemById = (itemId: string): UndefinableType<IItem> => {
    if (!menu.value || !menu.value.items) {
      return undefined;
    }
    return menu.value.items.find((item) => item.id === itemId);
  };
  const getCategoryById = (
    categoryId: UndefinableType<string>
  ): UndefinableType<ICategory> => {
    if (!categoryId || !menu.value || !menu.value.categories) return undefined;
    return menu.value.categories.find((c) => c.id === categoryId);
  };
  /**
   * Gets the content list for a given category
   * @param categoryId category identifier
   * @returns list of content associated with the category
   */
  const getCategoryContentById = (
    categoryId: UndefinableType<string>
  ): UndefinableType<TCategoryContent[]> => {
    const category = getCategoryById(categoryId);
    if (!category) return undefined;
    return category.content;
  };
  /**
   * Returns a list of categories that contain the item id
   * @param itemId Item identifier
   */
  const getAllCategoriesByItemId = (
    itemId: UndefinableType<string>
  ): UndefinableType<ICategory[]> => {
    if (!itemId || !menu.value || !menu.value.categories) return undefined;
    return menu.value.categories.filter((cat) => {
      if (!cat.content) return false;
      const ite = cat.content.find((e) => e.type === "ITEM" && e.id === itemId);
      if (ite) return true;
      else return false;
    });
  };
  const getCategoryContentItems = (
    categoryId: string
  ): UndefinableType<(IItem | ICategoryContentTitle)[]> => {
    if (!categoryId) return undefined;
    const category: UndefinableType<ICategory> = getCategoryById(categoryId);
    if (!category || !category.content) return undefined;

    const categoryItems: (IItem | ICategoryContentTitle)[] = [];
    category.content.forEach((content: TCategoryContent) => {
      if (content.type === "ITEM") {
        const item: IItem | undefined = getItemById(content.id);
        item && categoryItems.push(item);
      } else {
        categoryItems.push(content);
      }
    });
    return categoryItems;
  };
  const isStorageUpdating = computed<UndefinableType<boolean>>(() =>
    helpers.value && helpers.value.storageStatus
      ? helpers.value.storageStatus.isStorageUpdating
      : false
  );
  // const getStorageError = computed<UndefinableType<Error>>(() =>
  //   helpers.value && helpers.value.storageStatus
  //     ? helpers.value?.storageStatus.error
  //     : undefined
  // );
  const getMenuStyles = computed<UndefinableType<IMenuStyles>>(() =>
    menu.value ? menu.value.styles : undefined
  );
  const getBackgroundColor = computed<UndefinableType<string>>(() =>
    getMenuStyles.value ? getMenuStyles.value.backgroundColor : undefined
  );
  const getTitleFontName = computed<UndefinableType<string>>(() =>
    _getStyleFor("itemTitle", "fontName")
  );
  const getTitleFontSize = computed<UndefinableType<string>>(() =>
    _getStyleFor("itemTitle", "fontSize")
  );
  const getTitleFontColor = computed<UndefinableType<string>>(() =>
    _getStyleFor("itemTitle", "fontColor")
  );
  const getDescriptionFontName = computed<UndefinableType<string>>(() =>
    _getStyleFor("itemDescription", "fontName")
  );
  const getDescriptionFontSize = computed<UndefinableType<string>>(() =>
    _getStyleFor("itemDescription", "fontSize")
  );
  const getDescriptionFontColor = computed<UndefinableType<string>>(() =>
    _getStyleFor("itemDescription", "fontColor")
  );
  const getPriceFontName = computed<UndefinableType<string>>(() =>
    _getStyleFor("itemPrice", "fontName")
  );
  const getPriceFontSize = computed<UndefinableType<string>>(() =>
    _getStyleFor("itemPrice", "fontSize")
  );
  const getPriceFontColor = computed<UndefinableType<string>>(() =>
    _getStyleFor("itemPrice", "fontColor")
  );
  const getCategoryFontName = computed<UndefinableType<string>>(() =>
    _getStyleFor("topMenu", "fontName")
  );
  const getCategoryFontSize = computed<UndefinableType<string>>(() =>
    _getStyleFor("topMenu", "fontSize")
  );
  const getCategoryFontColor = computed<UndefinableType<string>>(() =>
    _getStyleFor("topMenu", "fontColor")
  );
  const getCategoryBackgroundColor = computed<UndefinableType<string>>(() =>
    _getStyleFor("topMenu", "backgroundColor")
  );
  const getCategorySelectedColor = computed<UndefinableType<string>>(() =>
    _getStyleFor("topMenu", "selectedColor")
  );
  const getSectionFontName = computed<UndefinableType<string>>(() =>
    _getStyleFor("categorySection", "fontName")
  );
  const getSectionFontSize = computed<UndefinableType<string>>(() =>
    _getStyleFor("categorySection", "fontSize")
  );
  const getSectionFontColor = computed<UndefinableType<string>>(() =>
    _getStyleFor("categorySection", "fontColor")
  );
  const getMenuTitleFontName = computed<UndefinableType<string>>(() =>
    _getStyleFor("menuTitle", "fontName")
  );
  const getMenuTitleFontSize = computed<UndefinableType<string>>(() =>
    _getStyleFor("menuTitle", "fontSize")
  );
  const getMenuTitleFontColor = computed<UndefinableType<string>>(() =>
    _getStyleFor("menuTitle", "fontColor")
  );
  const getMenuTitleBackgroundColor = computed<UndefinableType<string>>(() =>
    _getStyleFor("menuTitle", "backgroundColor")
  );
  const getItemsAssociationSummary = computed<ItemCategoryAssociationSummary>(
    () => {
      const result = {
        zero: [],
        one: [],
        multiple: [],
      } as ItemCategoryAssociationSummary;
      if (!menu.value || !menu.value.items) return result;

      menu.value.items.forEach((item) => {
        const cats = getAllCategoriesByItemId(item.id);
        if (!cats || cats.length === 0) {
          result.zero.push(item);
        } else if (cats.length === 1) {
          result.one.push(item);
        } else if (cats.length > 1) {
          result.multiple.push(item);
        }
      });
      return result;
    }
  );
  const getAllDisabledItems = computed<UndefinableType<IItem[]>>(() => {
    if (!menu.value || !menu.value.items) return undefined;
    return menu.value.items.filter((item) => item.enabled === false);
  });

  // Actions
  function reset() {
    _reset();
  }
  function setMenuId(id: string) {
    menuId.value = id;
  }
  function setMenu(mnu: IMenu) {
    menu.value = mnu;
  }
  function setDataUpdateOn() {
    if (!helpers.value) {
      helpers.value = _blankHelpers();
    }
    helpers.value.storageStatus.isStorageUpdating = true;
  }
  function setDataUpdateOff() {
    if (!helpers.value) {
      helpers.value = _blankHelpers();
    }
    helpers.value.storageStatus.isStorageUpdating = false;
  }
  function setEditItemId(itemId: UndefinableType<string>) {
    if (!helpers.value) {
      helpers.value = _blankHelpers();
    }
    helpers.value.editIds.editItemId = itemId;
  }
  function setEditCategoryId(categoryId: UndefinableType<string>) {
    if (!helpers.value) {
      helpers.value = _blankHelpers();
    }
    helpers.value.editIds.editCategoryId = categoryId;
  }
  function setSelectedCategoryId(categoryId: UndefinableType<string>) {
    if (!helpers.value) {
      helpers.value = _blankHelpers();
    }
    helpers.value.editIds.selectedCategoryId = categoryId;
  }
  function setSelectedSectionId(sectionId: UndefinableType<string>) {
    if (!helpers.value) {
      helpers.value = _blankHelpers();
    }
    helpers.value.editIds.selectedSectionId = sectionId;
  }

  return {
    reset,
    getMenuId,
    getMenu,
    getStatus,
    getBusinessInfo,
    getBusinessName,
    allItems,
    allCategories,
    getEditItemId,
    getEditCategoryId,
    getSelectedCategoryId,
    getSelectedSectionId,
    getItemById,
    getCategoryById,
    getCategoryContentById,
    getAllCategoriesByItemId,
    getCategoryContentItems,
    isStorageUpdating,
    getMenuStyles,
    getBackgroundColor,
    getTitleFontName,
    getTitleFontSize,
    getTitleFontColor,
    getDescriptionFontName,
    getDescriptionFontSize,
    getDescriptionFontColor,
    getPriceFontName,
    getPriceFontSize,
    getPriceFontColor,
    getCategoryFontName,
    getCategoryFontSize,
    getCategoryFontColor,
    getCategoryBackgroundColor,
    getCategorySelectedColor,
    getSectionFontName,
    getSectionFontSize,
    getSectionFontColor,
    getMenuTitleFontName,
    getMenuTitleFontSize,
    getMenuTitleFontColor,
    getMenuTitleBackgroundColor,
    getItemsAssociationSummary,
    setMenuId,
    setMenu,
    setDataUpdateOn,
    setDataUpdateOff,
    setEditItemId,
    setEditCategoryId,
    setSelectedCategoryId,
    setSelectedSectionId,
    getAllDisabledItems,
  };
});
