import {
  onAuthStateChanged,
  sendEmailVerification,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
import { auth } from "@/firebase/index";
import i18n from "@/i18n";
import {
  initializeSnapshot,
  getUserById,
  detachFirestore,
  initializeUserSnapshot,
} from "@/firebase/firestore";
import { useAppStore } from "@/stores/app-store";
import { getEmailVerificationLandingURL } from "@/configuration/app-config";
import { IUser, IUserStore } from "@/types/user";
import { useUserStore } from "@/stores/user-store";
import { useMenuStore } from "@/stores/menu-store";
import { storeError, clearStoredError } from "@/common/globalError";
import { getLocalStorageValue, LOCAL_STORAGE_KEYS } from "@/stores/local-store";
import { updateUserLanguague } from "@/firebase/functions";
import router from "@/router/router";

/**
 * 1. If !user then logout
 * 2. Save user details in user store
 * 3. If user not verified send verification
 * 4. Refresh user
 * 5. Get user details from FS
 * 6. Update user in user-store
 * 7. Login user to app
 */
onAuthStateChanged(auth, async (user: any) => {
  const userStore = useUserStore();
  const appStore = useAppStore();
  try {
    clearStoredError();

    if (i18n.global.locale.value) {
      auth.languageCode = i18n.global.locale.value;
    } else {
      auth.languageCode = "en";
    }

    // 1. If !user then logout
    if (!user) {
      appStore.setLoading(false);
      userStore.logout();
      return;
    }

    // 2. Save user details in user store
    const tokenResult = await user.getIdTokenResult();
    const language = getLocalStorageValue(LOCAL_STORAGE_KEYS.SELECTED_LANGUAGE);
    let storeUser: IUserStore = {
      uid: user.uid,
      email: user.email,
      createdOn: undefined,
      menuId: undefined,
      emailVerified: user.emailVerified,
      displayName: user.displayName,
      role: tokenResult.claims ? tokenResult.claims.role : undefined,
      status: tokenResult.claims ? tokenResult.claims.status : undefined,
      stripeRole: tokenResult.claims
        ? tokenResult.claims.stripeRole
        : undefined,
      language: language ? language : undefined,
    };
    userStore.setUser(storeUser);

    // 3. If user not verified send verification
    if (user && !user.emailVerified) {
      appStore.setLoading(true);
      const actionCodeSettings = {
        url: `${getEmailVerificationLandingURL()}?email=${
          auth.currentUser.email
        }`,
      };
      await sendEmailVerification(auth.currentUser, actionCodeSettings);
      appStore.setLoading(false);
      router.push({ name: "auth.verify" });
      return;
    }

    // 4. Refresh user
    // Needed to refresh token to get latest user info/display name
    auth.currentUser.getIdToken(true);

    // 5. Get user details from FS
    const fsUser: IUser = await getUserById(user.uid);

    if (!fsUser.language && language) {
      updateUserLanguague(language);
    }

    appStore.setLoading(false);

    // 6. Update user in user-store
    storeUser = {
      uid: user.uid,
      email: user.email,
      createdOn: fsUser.createdOn,
      menuId: fsUser.menuId,
      emailVerified: user.emailVerified,
      displayName: user.displayName,
      role: fsUser.role ? fsUser.role : storeUser.role,
      status: fsUser.status ? fsUser.status : storeUser.status,
      stripeRole: fsUser.stripeRole ? fsUser.stripeRole : storeUser.stripeRole,
      language: language ? language : undefined,
    };
    userStore.setUser(storeUser);

    // 7. Login user to app
    if (user && user.emailVerified) {
      initializeUserSnapshot(storeUser);
      if (storeUser.menuId && storeUser.menuId != "") {
        initializeSnapshot();
        const redirect = _getRedirectURL();
        router.push({ path: redirect || "/" });
      } else {
        router.push({ name: "menu.info" });
      }
    }
  } catch (error: any) {
    console.error("onAuthStateChanged error: ", error);
    storeError(error);
  }
});

async function passwordReset(email: string) {
  await sendPasswordResetEmail(auth, email);
}

async function userSignOut() {
  const userStore = useUserStore();
  const menuStore = useMenuStore();
  userStore.logout();
  userStore.reset();
  menuStore.reset();
  detachFirestore();
  await signOut(auth);
}

function _getRedirectURL(): string {
  const urlParams = new URLSearchParams(location.search);
  const redirect = urlParams.get("redirect");
  if (redirect) {
    return redirect;
  } else {
    return "/";
  }
}

export { passwordReset, userSignOut };
