/* eslint-disable prettier/prettier */
const NODE_ENV = process.env.NODE_ENV || "";

const DEV_TOOLS = process.env.VUE_APP_DEV_TOOLS && process.env.VUE_APP_DEV_TOOLS === "true" ? true : false;
const MENUMANIA_VIEWER_URL = process.env.VUE_APP_MENU_VIEWER_URL;
const TRIAL_DAYS = process.env.VUE_APP_TRIAL_DAYS ? parseInt(process.env.VUE_APP_TRIAL_DAYS) : 14;
const STRIPE_SUCCESS_URL = process.env.VUE_APP_STRIPE_SUCCESS_URL;
const STRIPE_CANCEL_URL = process.env.VUE_APP_STRIPE_CANCEL_URL;
const COMMERCIAL_SITE_URL = process.env.VUE_APP_COMMERCIAL_SITE_URL;

const FIREBASE_DEV = process.env.VUE_APP_FIREBASE_DEV || "false";
const DISPLAY_DEV_BANNER = process.env.VUE_APP_DISPLAY_DEV_BANNER || "true";
const FIRESTORE_URL = process.env.VUE_APP_FIRESTORE_URL || "localhost";
const FIRESTORE_PORT = process.env.VUE_APP_FIRESTORE_PORT ? parseInt(process.env.VUE_APP_FIRESTORE_PORT) : 8080;
const AUTH_URL = process.env.VUE_APP_AUTH_URL || "http://localhost:9099";
const STORAGE_URL = process.env.VUE_APP_STORAGE_URL || "localhost";
const STORAGE_PORT = process.env.VUE_APP_STORAGE_PORT ? parseInt(process.env.VUE_APP_STORAGE_PORT) : 9199;
const FUNCTIONS_URL = process.env.VUE_APP_FUNCTIONS_URL || "localhost";
const FUNCTIONS_PORT = process.env.VUE_APP_FUNCTIONS_PORT ? parseInt(process.env.VUE_APP_FUNCTIONS_PORT) : 5001;

// Production Configuration
const STORAGE_BUCKET = process.env.VUE_APP_STORAGE_BUCKET || "";
const AUTH_EMAIL_VERIFICATION_LANDING_URL = process.env.VUE_APP_AUTH_EMAIL_VERIFICATION_LANDING_URL || "";
const AUTH_EMAIL_RESET_LANDING_URL=process.env.VUE_APP_AUTH_EMAIL_RESET_LANDING_URL || "";
const FIREBASE_API_KEY = process.env.VUE_APP_FIREBASE_API_KEY || "";
const FIREBASE_AUTH_DOMAIN = process.env.VUE_APP_FIREBASE_AUTH_DOMAIN || "";
const FIREBASE_PROJECT_ID = process.env.VUE_APP_FIREBASE_PROJECT_ID || "";
const FIREBASE_MESSAGING_SENDER_ID = process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID || "";
const FIREBASE_APP_ID = process.env.VUE_APP_FIREBASE_APP_ID || "";
const FIREBASE_MEASUREMENT_ID = process.env.VUE_APP_FIREBASE_MEASUREMENT_ID || "";
const FIREBASE_REGION = process.env.VUE_APP_FIREBASE_REGION || "europe-west1";

const RECAPTCHA_SITE_KEY = process.env.VUE_APP_RECAPTCHA_SITE_KEY || "";

const FONT_SIZES = {
  XS: "0.6875rem",
  S: "0.8125rem",
  M: "1rem",
  L: "1.1875rem",
  XL: "1.4375rem",
  "2XL": "1.75rem",
  "3XL": "2.0625rem",
  "4XL": "2.5rem",
  "5XL": "3rem",
};

function getFontSizeIdentifiers() {
  return Object.keys(FONT_SIZES);
}

function getFirebaseRegion() {
  return FIREBASE_REGION;
}

function getFunctionsUrl_local() {
  return FUNCTIONS_URL;
}

function getFunctionsPort_local() {
  return FUNCTIONS_PORT;
}

function getFirebaseApiKey() {
  return FIREBASE_API_KEY;
}

function getFirebaseAuthDomain() {
  return FIREBASE_AUTH_DOMAIN;
}

function getFirebaseProjectId() {
  return FIREBASE_PROJECT_ID;
}

function getFirebaseMessagingSenderId() {
  return FIREBASE_MESSAGING_SENDER_ID;
}

function getFirebaseAppId() {
  return FIREBASE_APP_ID;
}

function getFirebaseMeasurementId() {
  return FIREBASE_MEASUREMENT_ID;
}

function displayDevBanner(): boolean {
  if (DISPLAY_DEV_BANNER === "false") {
    // This is needed to hide the conncected to dev environemt message
    // It interferes with cypress screen shots
    return false;
  }
  return isLocalDevEnabled();
}

function isLocalDevEnabled(): boolean {
  if (NODE_ENV === "development" && FIREBASE_DEV === "true") {
    return true;
  }
  return false;
}

function getFirestoreUrl_local(): string {
  return FIRESTORE_URL;
}

function getFirestorePort_local(): number {
  return FIRESTORE_PORT;
}

function getAuthURL_local(): string {
  return AUTH_URL;
}

function getEnvironment_local(): string {
  return NODE_ENV;
}

function getStorageUrl_local(): string {
  return STORAGE_URL;
}

function getStoragePort_local(): number {
  return STORAGE_PORT;
}

function getEmailVerificationLandingURL(): string {
  return AUTH_EMAIL_VERIFICATION_LANDING_URL;
}

function getAuthEmailResetLandingURL() {
  return AUTH_EMAIL_RESET_LANDING_URL;
}

export {
  isLocalDevEnabled,
  displayDevBanner,
  getFirestoreUrl_local,
  getFirestorePort_local,
  getAuthURL_local,
  getEnvironment_local,
  getStorageUrl_local,
  getStoragePort_local,
  getEmailVerificationLandingURL,
  getFirebaseApiKey,
  getFirebaseAuthDomain,
  getFirebaseProjectId,
  getFirebaseMessagingSenderId,
  getFirebaseAppId,
  getFirebaseMeasurementId,
  getFunctionsUrl_local,
  getFunctionsPort_local,
  getFirebaseRegion,
  FONT_SIZES,
  getFontSizeIdentifiers,
  getAuthEmailResetLandingURL,
  DEV_TOOLS,
  MENUMANIA_VIEWER_URL,
  STORAGE_BUCKET,
  TRIAL_DAYS,
  STRIPE_SUCCESS_URL,
  STRIPE_CANCEL_URL,
  COMMERCIAL_SITE_URL,
  RECAPTCHA_SITE_KEY,
};
