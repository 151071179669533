import {
  ref,
  getStorage,
  uploadBytes,
  getDownloadURL,
  deleteObject,
  connectStorageEmulator,
  UploadResult,
} from "firebase/storage";
import { BucketImage, ImageUpload } from "@/types/general";
import { app, getMenuStore, getUserStore } from "./index";
import {
  isLocalDevEnabled,
  getStorageUrl_local,
  getStoragePort_local,
  STORAGE_BUCKET,
} from "@/configuration/app-config";
import { storeError } from "@/common/globalError";

const storageWrite = getStorage(app, STORAGE_BUCKET);

if (isLocalDevEnabled()) {
  connectStorageEmulator(
    storageWrite,
    getStorageUrl_local(),
    getStoragePort_local()
  );
}

async function deleteImages(images: BucketImage[]) {
  try {
    if (!images || images.length === 0) return;
    const IMG_LARGE = "640x480";
    const IMG_SMALL = "200x200";

    getMenuStore().setDataUpdateOn();
    const promises: any[] = [];
    images.forEach((img) => {
      const orgFilePath = _imageFullPath(img.name);
      const lgFilePath = _imageFullPath(
        _appendStrToFilename(img.name, IMG_LARGE)
      );
      const smFilePath = _imageFullPath(
        _appendStrToFilename(img.name, IMG_SMALL)
      );
      const orgImageRef = ref(storageWrite, orgFilePath);
      const lgImageRef = ref(storageWrite, lgFilePath);
      const smImageRef = ref(storageWrite, smFilePath);
      promises.push(deleteObject(orgImageRef));
      promises.push(deleteObject(lgImageRef));
      promises.push(deleteObject(smImageRef));
    });

    await Promise.all(promises);
    getMenuStore().setDataUpdateOff();
  } catch (error: any) {
    storeError(error);
  }
}

async function uploadImages(
  fileObjects: ImageUpload[]
): Promise<BucketImage[]> {
  try {
    getMenuStore().setDataUpdateOn();
    const promises: any = [];
    const metadata = {
      contentDisposition: "inline",
    };
    for (const fi of fileObjects) {
      const fullFileName = _imageFullPath(fi.name);
      const uploadImageRef = ref(storageWrite, fullFileName);
      fi.file && promises.push(uploadBytes(uploadImageRef, fi.file, metadata));
    }

    const uploadResults: UploadResult[] = await Promise.all(promises);

    const result: BucketImage[] = [];

    for (const r of uploadResults) {
      const orgUrl = await getDownloadURL(r.ref);
      const bucketImage: BucketImage = {
        name: r.metadata.name,
        orgUrl,
      };
      result.push(bucketImage);
    }

    getMenuStore().setDataUpdateOff();
    return result;
  } catch (error: any) {
    storeError(new Error("Image upload error"));
    getMenuStore().setDataUpdateOff();
    throw error;
  }
}

function _imageFullPath(filename: string) {
  if (!filename || filename.length === 0)
    throw new Error("filename cannot be empty");
  const uid = getUserStore().getUid;
  const menuid = getMenuStore().getMenuId;
  return `/itemimages/${uid}/${menuid}/${filename}`;
}

function _appendStrToFilename(filename: string, strToAppend: string): string {
  const newFilename =
    filename.split(".").slice(0, -1).join(".") +
    "-" +
    strToAppend +
    "." +
    filename.split(".").pop();
  return newFilename;
}

export { deleteImages, uploadImages };
