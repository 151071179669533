import { createI18n } from "vue-i18n";
import { detectBrowserLanguage } from "@/configuration/helpers";
import en from "@/locales/en.json";
import es from "@/locales/es.json";

const userLang = detectBrowserLanguage();

const i18n = createI18n({
  legacy: false,
  locale: userLang,
  fallbackLocale: "en",
  silentTranslationWarn: true,
  globalInjection: true,
  messages: {
    en,
    es,
  },
  numberFormats: {
    en: {
      currency: {
        style: "currency",
        currency: "USD",
        notation: "standard",
      },
      decimal: {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      nodecimal: {
        style: "decimal",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      },
    },
    es: {
      currency: {
        style: "currency",
        currency: "USD",
        notation: "standard",
      },
      decimal: {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      nodecimal: {
        style: "decimal",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      },
    },
  },
});

export default i18n;
