import {
  getFirebaseApiKey,
  getFirebaseAuthDomain,
  getFirebaseProjectId,
  getFirebaseMessagingSenderId,
  getFirebaseAppId,
  getFirebaseMeasurementId,
} from "@/configuration/app-config";

export default {
  apiKey: getFirebaseApiKey(),
  authDomain: getFirebaseAuthDomain(),
  projectId: getFirebaseProjectId(),
  messagingSenderId: getFirebaseMessagingSenderId(),
  appId: getFirebaseAppId(),
  measurementId: getFirebaseMeasurementId(),
};
