const LOCAL_STORAGE_KEYS = {
  SELECTED_LANGUAGE: "SELECTED_LANGUAGE",
  LOADING: "LOADING",
  UID: "UID",
  ERROR: "ERROR", // used to store error messages
};

function setLocalStorageValue(key: string, value: string) {
  // validate key belongs to LOCAL_STORAGE_KEYS
  const keyToUse = LOCAL_STORAGE_KEYS[key];
  if (!keyToUse) {
    throw new Error("Invalid key");
  }
  const localStorage = window.localStorage;
  localStorage.setItem(keyToUse, value);
}

function getLocalStorageValue(key: string) {
  // validate key belongs to LOCAL_STORAGE_KEYS
  const keyToUse = LOCAL_STORAGE_KEYS[key];
  if (!keyToUse) {
    throw new Error("Invalid key");
  }
  const localStorage = window.localStorage;
  return localStorage.getItem(keyToUse);
}

function removeLocalStorageValue(key: string) {
  // validate key belongs to LOCAL_STORAGE_KEYS
  const keyToUse = LOCAL_STORAGE_KEYS[key];
  if (!keyToUse) {
    throw new Error("Invalid key");
  }
  const localStorage = window.localStorage;
  localStorage.removeItem(keyToUse);
}

export {
  LOCAL_STORAGE_KEYS,
  setLocalStorageValue,
  getLocalStorageValue,
  removeLocalStorageValue,
};
