import { ACCOUNT_STATUS } from "@/types/user";
import { TRIAL_DAYS } from "@/configuration/app-config";

export function statusMessageHelper(
  status: string,
  calculateDays: boolean,
  createdOn: Date | undefined,
  t: any
): string | undefined {
  let message: string | undefined = undefined;
  if (!status) return message;

  switch (status) {
    case ACCOUNT_STATUS.TRIAL_ACTIVE:
      calculateDays
        ? (message = _trialStatus(createdOn, t))
        : (message = t("trial"));
      break;
    case ACCOUNT_STATUS.TRIAL_EXPIRED:
      message = t("statusTrialExpired");
      break;
    case ACCOUNT_STATUS.STRIPE_PAST_DUE:
      message = t("statusStripePastDue");
      break;
    case ACCOUNT_STATUS.STRIPE_UNPAID:
      message = t("statusStripeUnpaid");
      break;
    case ACCOUNT_STATUS.STRIPE_CANCELED:
      message = t("statusStripeCanceled");
      break;
    case ACCOUNT_STATUS.STRIPE_INCOMPLETE:
      message = t("statusStripeIncomplete");
      break;
    case ACCOUNT_STATUS.STRIPE_INCOMPLETE_EXPIRED:
      message = t("statusImcompleteExpired");
      break;
    case ACCOUNT_STATUS.STRIPE_TRAILING:
      message = t("statusStripeTrailing");
      break;
    case ACCOUNT_STATUS.STRIPE_PAUSED:
      message = t("statusStripePaused");
      break;
    default:
      message = undefined;
  }
  return message;
}

function _trialStatus(createdOn: Date | undefined, t: any): string | undefined {
  if (!createdOn) {
    return undefined;
  }

  const now = new Date();
  const diffInDays: number = Math.floor(
    Math.abs(createdOn.getTime() - now.getTime()) / 86400000
  );
  const daysLeft = TRIAL_DAYS - diffInDays;

  if (daysLeft > 0) {
    return t("statusTrialDaysLeft", { daysLeft });
  } else if (daysLeft <= 0) {
    return t("statusTrialExpired");
  } else {
    return undefined;
  }
}
