import {
  LOCAL_STORAGE_KEYS,
  setLocalStorageValue,
} from "../stores/local-store";

function storeError(error: Error) {
  setLocalStorageValue(LOCAL_STORAGE_KEYS.ERROR, error.message);
}

function getStoredError(): string | undefined {
  const error = localStorage.getItem(LOCAL_STORAGE_KEYS.ERROR);
  if (!error) {
    return undefined;
  }
  return error;
}

function clearStoredError() {
  localStorage.removeItem(LOCAL_STORAGE_KEYS.ERROR);
}

export { storeError, getStoredError, clearStoredError };
