import { createRouter, createWebHistory } from "vue-router";
import { useUserStore } from "@/stores/user-store";
import { DEV_TOOLS } from "@/configuration/app-config";
import NotFound from "@/components/views/NotFound.vue";

import menuRoutes from "@/router/menu-routes";
import authRoutes from "@/router/auth-routes";
import devRoutes from "@/router/dev-routes";
import accountRoutes from "@/router/account-routes";
import checkoutRoutes from "@/router/checkout-routes";

let routes = [
  {
    path: "",
    name: "home",
    redirect: { name: "menu.content" },
  },
  {
    path: "/:notFound(.*)",
    name: "notfound",
    component: NotFound,
  },
];

routes = routes
  .concat(menuRoutes as any)
  .concat(authRoutes as any)
  .concat(accountRoutes as any)
  .concat(checkoutRoutes as any)
  .concat(devRoutes as any);

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, _, next) => {
  const userStore = useUserStore();

  if (to.meta.devTools && !DEV_TOOLS) {
    next({ name: "notfound" });
    return;
  }

  if (to.meta.requiresAuth && !userStore.isAuth) {
    next({ name: "auth.login", query: { redirect: to.path } });
  } else if (to.meta.requiresUnauth && userStore.isAuth) {
    next("/menu/content");
  } else if (to.meta.requiresActive && !userStore.isUserActive) {
    next({ name: "account.settings" });
  } else {
    next();
  }
});

export default router;
