import {
  getFunctions,
  connectFunctionsEmulator,
  httpsCallable,
} from "firebase/functions";
import { app } from "./index";
import {
  isLocalDevEnabled,
  getFunctionsUrl_local,
  getFunctionsPort_local,
  getFirebaseRegion,
} from "@/configuration/app-config";
import { IMenu } from "@/types/menu";

const functions = getFunctions(app, getFirebaseRegion());

if (isLocalDevEnabled()) {
  connectFunctionsEmulator(
    functions,
    getFunctionsUrl_local(),
    getFunctionsPort_local()
  );
}

async function createMenu(menu: IMenu) {
  const createMenu = httpsCallable(functions, "createMenu");
  await createMenu(menu);
}

async function getCustomerBillingPortalUrl(): Promise<string> {
  const createMenu = httpsCallable(functions, "subscriptionPortal");
  const response: any = await createMenu();
  return response.data.url;
}

async function updateUserLanguague(language: string): Promise<any> {
  if (!language) throw new Error("Missing languague parameter");
  const updateLanguague = httpsCallable(functions, "updateUserLanguague");
  const response: any = await updateLanguague({ language });
  return response.data.url;
}

export { createMenu, getCustomerBillingPortalUrl, updateUserLanguague };
