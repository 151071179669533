const devRoutes = [
  {
    path: "/dev",
    component: () =>
      import(
        /* webpackChunkName: "group-dev" */ "@/components/views/LoggedinView.vue"
      ),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        redirect: "notfound",
      },
      {
        path: "theme",
        name: "dev.theme",
        component: () =>
          import(
            /* webpackChunkName: "group-dev" */ "@/components/dev/ThemeViewer.vue"
          ),
        meta: {
          devTools: true,
        },
      },
      {
        path: "playground",
        name: "dev.playground",
        component: () =>
          import(
            /* webpackChunkName: "group-dev" */ "@/components/dev/PlaygroundView.vue"
          ),
        meta: {
          devTools: true,
        },
      },
      {
        path: "fonts",
        name: "dev.fonts",
        component: () =>
          import(
            /* webpackChunkName: "group-dev" */ "@/components/dev/FontsViewer.vue"
          ),
        meta: {
          devTools: true,
        },
      },
    ],
  },
];

export default devRoutes;
