import LoggedinView from "@/components/views/LoggedinView.vue";
import AccountSettings from "@/components/account/AccountSettings.vue";

const accountRoutes = [
  {
    path: "/account",
    component: LoggedinView,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        redirect: "notfound",
      },
      {
        path: "settings",
        name: "account.settings",
        component: AccountSettings,
      },
    ],
  },
];

export default accountRoutes;
