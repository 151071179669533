import { ref, computed } from "vue";
import { IUserStore } from "@/types/user";
import { defineStore } from "pinia";
import { UndefinableType } from "@/types/general";
import {
  setLocalStorageValue,
  removeLocalStorageValue,
  getLocalStorageValue,
  LOCAL_STORAGE_KEYS,
} from "@/stores/local-store";
import { ACCOUNT_STATUS } from "@/types/user";

export const useUserStore = defineStore("UserStore", () => {
  // State
  const user = ref<UndefinableType<IUserStore>>();

  const getUser = computed<UndefinableType<IUserStore>>(() => user.value);
  const getUid = computed<UndefinableType<string>>(() => {
    if (!user.value) {
      return undefined;
    }
    return user.value.uid;
  });
  const isAuth = computed<boolean>(() => {
    if (user.value && user.value.uid && user.value.emailVerified) {
      return true;
    } else {
      return false;
    }
  });
  const getStatus = computed<UndefinableType<string>>(() => user.value?.status);
  const isUserActive = computed<boolean>(() => {
    if (
      user.value?.status === ACCOUNT_STATUS.TRIAL_ACTIVE ||
      user.value?.status === ACCOUNT_STATUS.STRIPE_ACTIVE
    ) {
      return true;
    } else {
      return false;
    }
  });

  // Actions
  function reset() {
    user.value = undefined;
  }

  function setUser(usr: IUserStore) {
    user.value = usr;
    setLocalStorageValue(LOCAL_STORAGE_KEYS.UID, usr.uid);
  }

  function logout() {
    user.value = undefined;
    removeLocalStorageValue(LOCAL_STORAGE_KEYS.UID);
  }

  function isUserLocallyLoggedIn() {
    const localUID = getLocalStorageValue(LOCAL_STORAGE_KEYS.UID);
    if (user.value && !user.value.emailVerified) {
      return false;
    }
    if (!isAuth.value && localUID) {
      return true;
    }
    return false;
  }
  return {
    reset,
    getUser,
    getStatus,
    isUserActive,
    getUid,
    isAuth,
    setUser,
    logout,
    isUserLocallyLoggedIn,
  };
});
