/* eslint-disable no-unused-vars */
enum ACCOUNT_STATUS {
  TRIAL_ACTIVE = "trial",
  TRIAL_EXPIRED = "trial_expired",
  STRIPE_ACTIVE = "active",
  STRIPE_PAST_DUE = "past_due",
  STRIPE_UNPAID = "unpaid",
  STRIPE_CANCELED = "canceled",
  STRIPE_INCOMPLETE = "incomplete",
  STRIPE_INCOMPLETE_EXPIRED = "incomplete_expired",
  STRIPE_TRAILING = "trialing",
  STRIPE_PAUSED = "paused",
}

interface IUser {
  uid: string;
  email: string;
  createdOn?: Date;
  menuId?: string;
  role?: string;
  status?: string;
  stripeRole?: string;
  language?: string;
}

interface IUserStore extends IUser {
  emailVerified: boolean;
  displayName: string;
}

export type { IUser, IUserStore };
export { ACCOUNT_STATUS };
