import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { useMenuStore } from "@/stores/menu-store";
import { useUserStore } from "@/stores/user-store";
import firebaseConfig from "./firebase-config";
import {
  isLocalDevEnabled,
  getAuthURL_local,
} from "@/configuration/app-config";
import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from "firebase/app-check";
import { RECAPTCHA_SITE_KEY } from "@/configuration/app-config";

const app = initializeApp(firebaseConfig);

if (!isLocalDevEnabled()) {
  initializeAppCheck(app, {
    provider: new ReCaptchaEnterpriseProvider(RECAPTCHA_SITE_KEY),
    isTokenAutoRefreshEnabled: true,
  });
}

let menuStore: any;
function getMenuStore() {
  if (!menuStore) {
    menuStore = useMenuStore();
  }
  return menuStore;
}

let userStore: any;
function getUserStore() {
  if (!userStore) {
    userStore = useUserStore();
  }
  return userStore;
}

let auth: any;
if (isLocalDevEnabled()) {
  auth = getAuth();
  connectAuthEmulator(auth, getAuthURL_local());
} else {
  auth = getAuth(app);
}

export { app, auth, getMenuStore, getUserStore };
