const checkoutRoutes = [
  {
    path: "/checkout",
    component: () =>
      import(
        /* webpackChunkName: "group-checkout" */ "@/components/views/PlainView.vue"
      ),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        redirect: "notfound",
      },
      {
        path: "plans",
        name: "checkout.plans",
        components: {
          default: () =>
            import(
              /* webpackChunkName: "group-checkout" */ "@/components/subscription/CheckoutPlans.vue"
            ),
        },
      },
      {
        path: "success",
        name: "checkout.success",
        components: {
          default: () =>
            import(
              /* webpackChunkName: "group-checkout" */ "@/components/subscription/CheckoutSuccess.vue"
            ),
        },
      },
      {
        path: "cancel",
        name: "checkout.cancel",
        components: {
          default: () =>
            import(
              /* webpackChunkName: "group-checkout" */ "@/components/subscription/CheckoutCancel.vue"
            ),
        },
      },
    ],
  },
];

export default checkoutRoutes;
