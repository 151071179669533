const menuRoutes = [
  {
    path: "/menu",
    component: () =>
      import(
        /* webpackChunkName: "group-menu" */ "@/components/views/LoggedinView.vue"
      ),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        redirect: "notfound",
      },
      {
        path: "content",
        name: "menu.content",
        meta: {
          requiresActive: true,
        },
        components: {
          default: () =>
            import(
              /* webpackChunkName: "group-menu" */ "@/components/menu/MenuEdit.vue"
            ),
        },
      },
      {
        path: "qr",
        name: "menu.qr",
        meta: {
          requiresActive: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "group-menu" */ "@/components/menu/QRCodeViewer.vue"
          ),
      },
      {
        path: "foods",
        name: "menu.foods",
        meta: {
          requiresActive: true,
        },
        components: {
          default: () =>
            import(
              /* webpackChunkName: "group-menu" */ "@/components/fooditem/FoodList.vue"
            ),
        },
      },
      {
        path: "info",
        name: "menu.info",
        meta: {
          requiresActive: true,
        },
        components: {
          default: () =>
            import(
              /* webpackChunkName: "group-menu" */ "@/components/menuinfo/MenuInfo.vue"
            ),
        },
      },
      {
        path: "styling",
        name: "menu.styling",
        meta: {
          requiresActive: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "group-menu" */ "@/components/menustyling/MenuStyling.vue"
          ),
      },
    ],
  },
];

export default menuRoutes;
