import Swal, { SweetAlertIcon, SweetAlertOptions } from "sweetalert2";

async function confirmAlert(
  text: string,
  confirmButtonText: string,
  cancelButtonText: string,
  icon: SweetAlertIcon = "warning"
) {
  const options: SweetAlertOptions = {
    text,
    confirmButtonText,
    cancelButtonText,
  };
  return _swalAlert(options, icon);
}

async function confirmAlertTitle(
  title: string,
  text: string,
  confirmButtonText: string,
  cancelButtonText: string,
  icon: SweetAlertIcon = "warning"
) {
  const options: SweetAlertOptions = {
    title,
    text,
    confirmButtonText,
    cancelButtonText,
  };
  return _swalAlert(options, icon);
}

async function confirmAlternativeAlertTitle(
  title: string,
  text: string,
  confirmButtonText: string,
  cancelButtonText: string,
  denyButtonText: string,
  icon: SweetAlertIcon = "warning"
) {
  const options: SweetAlertOptions = {
    title,
    text,
    showDenyButton: true,
    showCancelButton: true,
    confirmButtonText,
    cancelButtonText,
    denyButtonText,
    customClass: {
      confirmButton: "mm-swal-alert-accent",
      denyButton: "mm-swal-alert-confirm",
      cancelButton: "mm-swal-alert-cancel",
    },
  };
  return _swalAlert(options, icon);
}

async function messageAlert(title: string) {
  const options: SweetAlertOptions = {
    title,
    icon: "success",
    scrollbarPadding: false,
    showCancelButton: false,
    customClass: {
      confirmButton: "mm-swal-alert-confirm",
    },
  };
  return Swal.fire(options);
}

async function _swalAlert(options: SweetAlertOptions, icon: SweetAlertIcon) {
  options.icon = icon;
  options.scrollbarPadding = false;
  options.showCancelButton = true;
  if (!options.customClass) {
    options.customClass = {
      confirmButton: "mm-swal-alert-confirm",
      denyButton: "mm-swal-alert-confirm",
      cancelButton: "mm-swal-alert-cancel",
    };
  }
  return Swal.fire(options);
}

export {
  confirmAlert,
  confirmAlertTitle,
  messageAlert,
  confirmAlternativeAlertTitle,
};
