const authRoutes = [
  {
    path: "/auth",
    component: () =>
      import(
        /* webpackChunkName: "group-auth" */ "@/components/views/AuthMain.vue"
      ),
    meta: {
      requiresUnauth: true,
    },
    children: [
      {
        path: "",
        redirect: "notfound",
      },
      {
        path: "login",
        name: "auth.login",
        component: () =>
          import(
            /* webpackChunkName: "group-auth" */ "@/components/auth/UserAuth.vue"
          ),
        meta: {
          requiresUnauth: true,
        },
      },
      {
        path: "register",
        name: "auth.signup",
        component: () =>
          import(
            /* webpackChunkName: "group-auth" */ "@/components/auth/SignUp.vue"
          ),
        meta: {
          requiresUnauth: true,
        },
      },
      {
        path: "verify",
        name: "auth.verify",
        component: () =>
          import(
            /* webpackChunkName: "group-auth" */ "@/components/auth/VerificationEmail.vue"
          ),
        meta: {
          requiresUnauth: true,
        },
      },
      {
        path: "verified",
        name: "auth.verified",
        component: () =>
          import(
            /* webpackChunkName: "group-auth" */ "@/components/auth/EmailVerified.vue"
          ),
        meta: {
          requiresUnauth: true,
        },
      },
      {
        path: "passwordreset",
        name: "auth.resetpassword",
        component: () =>
          import(
            /* webpackChunkName: "group-auth" */ "@/components/auth/ResetPassword.vue"
          ),
        meta: {
          requiresUnauth: true,
        },
      },
      {
        path: "resetsent",
        name: "auth.resetpasswordsent",
        component: () =>
          import(
            /* webpackChunkName: "group-auth" */ "@/components/auth/ResetPasswordSent.vue"
          ),
        meta: {
          requiresUnauth: true,
        },
      },
      {
        path: "signoutfromtheapp",
        name: "auth.signout",
        component: () =>
          import(
            /* webpackChunkName: "group-auth" */ "@/components/auth/SignOut.vue"
          ),
        // meta: {
        //   requiresUnauth: true,
        // },
      },
    ],
  },
];

export default authRoutes;
