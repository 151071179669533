function blurActiveElement() {
  const elem: any = document.activeElement;
  if (elem && elem.blur) {
    elem.blur();
  }
}

function delay(time: number) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

function modalScrollBackgroundOff() {
  const scrollY = document.body.scrollTop;
  document.body.style.position = "fixed";
  document.body.style.top = `-${window.scrollY}px`;
  // document.body.style.width = "100%";
  return scrollY;
}

function modalScrollBackgroundOn() {
  document.body.style.position = "";
  document.body.style.top = "";
  // document.body.style.width = "";
}

export {
  blurActiveElement,
  delay,
  modalScrollBackgroundOff,
  modalScrollBackgroundOn,
};
