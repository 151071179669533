import "flag-icons/css/flag-icons.min.css";
import { createApp } from "vue";
import { createPinia } from "pinia";
import VueGtag from "vue-gtag";
import i18n from "@/i18n";
import router from "@/router/router";
import LocalisedText from "@/components/common/LocalisedText.vue";
import App from "./App.vue";

// Store
const pinia = createPinia();

const app = createApp(App);
app.use(VueGtag, {
  id: "AW-11168381124",
});
app.use(pinia);
app.use(i18n);
app.use(router);
app.component("LocalisedText", LocalisedText);
app.mount("#app");

import "@/styles/app.css";
import "@/firebase/auth";
