import { computed, ref } from "vue";
import { defineStore } from "pinia";
import {
  setLocalStorageValue,
  getLocalStorageValue,
  LOCAL_STORAGE_KEYS,
} from "@/stores/local-store";

export const useAppStore = defineStore("AppStore", () => {
  // State
  const loading = ref<boolean>(true);

  // Getters
  const isLoading = computed<boolean>(() => loading.value);

  // Actions
  function setLoading(lding: boolean) {
    setLocalStorageValue(LOCAL_STORAGE_KEYS.LOADING, "" + lding);
    loading.value = lding;
  }

  function setLoadingFromStorage() {
    const loadingStorage = getLocalStorageValue(LOCAL_STORAGE_KEYS.LOADING);
    if (loadingStorage === "true") {
      loading.value = true;
    } else {
      loading.value = false;
    }
  }

  return { isLoading, setLoading, setLoadingFromStorage };
});
